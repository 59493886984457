import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import {
  TopNav,
  ImageGallery,
  ContactUs,
  Footer,
  SeoHeader,
} from "../components";
import "../styles/app.scss";

const NAV_OPTIONS = [{ label: "home", href: "/" }];

// markup
const GalleryPage = () => {
  const imageGalleryData = useStaticQuery(graphql`
    query ImageGalleryQuery {
      galleryPhotos: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "gallery" }
        }
        sort: { fields: changeTime, order: DESC }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
                quality: 90
              )
            }
            base
            id
          }
        }
      }
    }
  `);

  return (
    <>
      <SeoHeader />
      <TopNav dynamicBg={false} navOptions={NAV_OPTIONS} />
      <Container className="mt-5 py-5">
        <h1 className="mb-4 mt-5">Our Work</h1>
        <p>
          We are proud of our attention to detail and craftsmanship. From
          planning to construction, we work with you to ensure your dream home
          comes true.
        </p>
        <p>
          Please take time to view some of the recent work we have done for our
          satisfied clients.
        </p>
        <ImageGallery data={imageGalleryData} />
      </Container>
      <ContactUs />
      <Footer />
    </>
  );
};

export default GalleryPage;
